<template>
     <div class="learn-container clearfix">
      <div class="learn-container-left">
        <div class="learn-container-nav clearfix">
          <a href="javascript:;" class="active">章节</a>
        </div>
        <div class="learn-course-list">
          <div v-for="(v,i) in dataList" :key="i" class="list-item">
            <h3>第{{i+1}}章 {{v.title}}</h3>
            <ul>
              <li  v-for="(d,i1) in v.list" :key="i1">
                <i :class="{'imv2-shipin3':!d.isOk,'imv2-wancheng3':d.isOk}" class=" ileft"></i>
                <em class="type-text">视频：</em>
                <a target="_blank" href="#/lesson/187.html#mid=21261">
                  <span class="title_info">{{`${i+1}-${i1+1}`}} {{d.title}} </span>
                  <span class="course-time r"> {{d.date}} </span>
                </a>
              </li>
            </ul>
          </div>
          <div class="chapter-update-state clearfix">
            <i class="imv2-check"></i>
            <span>本课程已完结</span>
          </div>
        </div>

        <div class="learning_progress learning_progress_static">
          <a
            href=""
            class="learning_progress_head js-header-avator"
          >
            <img
              :src="navbar.image"
              width="36"
              height="36"
            />
          </a>

          <dl>
            <dt>学习进度</dt>
            <dd>{{navbar.jd}}<label></label></dd>
          </dl>

          <dl>
            <dt>获得经验</dt>
            <dd>{{navbar.jy}}</dd>
          </dl>

          <dl>
            <dt>学习用时</dt>
            <dd>{{navbar.ys}}</dd>
          </dl>

          <a href="/lesson/187.html#mid=24123" class="continue_learn">
            继续学习
          </a>
          <span>上次学至：1-1 SpringCloud导学</span>
        </div>

        <div
          class="learning_progress learning_progress_fixed"
          style="visibility: visible; position: fixed"
        >
          <a
            href=""
            class="learning_progress_head js-header-avator"
          >
            <img
               :src="navbar.image"
              width="36"
              height="36"
            />
          </a>


          <dl>
            <dt>学习进度</dt>
            <dd>{{navbar.jd}}<label></label></dd>
          </dl>

          <dl>
            <dt>获得经验</dt>
            <dd>{{navbar.jy}}</dd>
          </dl>

          <dl>
            <dt>学习用时</dt>
            <dd>{{navbar.ys}}</dd>
          </dl>

          <a href="#/video?mid=24123" class="continue_learn">
            继续学习
          </a>
          <span>上次学至：1-1 SpringCloud导学</span>
        </div>
      </div>

      <div class="learn-course-right">
        <div class="course-sidebar-title">
          <h3 class="clearfix">
            <span class="span">课程摘要</span>
          </h3>
        </div>

        <div class="lecturer-instr">
          <a
            class="lecturer-head"
            href="//www.imooc.com/t/4559066"
            target="_blank"
          >
            <img
              :src="zy.tacher.image"
            />
          </a>

          <div class="lecturer-name">
            <a
              class="clearfix"
              href="//www.imooc.com/t/4559066"
              target="_blank"
            >
              <span>{{zy.tacher.name}}</span>
              <i class="id_icon_lecturer"></i>
            </a>
          </div>

          <div class="lecturer-title">{{zy.tacher.zw}}</div>
          <p>{{zy.tacher.p}}</p>
        </div>
        <div
          class="lecturer-sz-recommend js-lecturer-sz-recommend"
          style="display: block"
        >
          <h3 class="clearfix">
            <span class="span">讲师课程推荐</span>
            <a
              class="clearfix"
              target="_blank"
              href="//www.imooc.com/t/4559066"
            >
              <span>更多</span>
              <i>&gt;</i>
            </a>
          </h3>

          <ul class="moco-aside-course" id="lecturerCourse">
            <li v-for="(v,i) in zy.tacher.list" :key="i">
              <div class="aside-course-img">
                <img :src="v.image"/>
                <p class="aside-course-type">{{v.flg?"实战":"免费"}}</p>
              </div>
              <div class="aside-course-content">
                <a
                  target="_blank"
                  href="/class/117.html"
                  class="aside-course-name"
                >{{v.title}}</a>
                <p class="aside-course-price">￥{{v.jd}}</p>
                <div class="aside-course-dot">
                  <i class="imv2-dot_samll"></i>
                </div>
                <p class="aside-course-grade">中级</p>
                <div class="aside-course-dot">
                  <i class="imv2-dot_samll"></i>
                </div>
                <p class="aside-course-people">
                  <i class="imv2-set-sns"></i> <span>{{v.rs}}</span>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
export default {
data(){
  return{
    navbar:{
      jd:"89%",
      jy:"895",
      ys:"41小时7分钟",
      xxz:"1-1 SpringCloud导学",
      image:"https://ts1.cn.mm.bing.net/th?id=OIP-C.AX66Lftteq7tfSSy_xlwwgAAAA&w=99&h=100&c=8&rs=1&qlt=90&o=6&dpr=1.25&pid=3.1&rm=2"
    },
    dataList:[
      {
        title:"课程介绍",
        list:  [
          {
            isOk:true,
            title:"是长时间",
            date:"(20:88)",
            isOder:true,
             url:""
          },
           {
            isOk:true,
            title:"是长时间",
            date:"(20:88)",
            isOder:true,
            url:""
          },
           {
            isOk:true,
            title:"是长时间",
            date:"(20:88)",
            isOder:true,
            url:""
          }
        ]
      },
       {
        title:"课程介绍",
        list:  [
          {
            isOk:false,
            title:"是长时间",
            date:"(20:88)",
            isOder:true,
             url:""
          },
           {
            isOk:true,
            title:"是长时间",
            date:"(20:88)",
            isOder:true,
            url:""
          },
           {
            isOk:true,
            title:"是长时间",
            date:"(20:88)",
            isOder:true,
            url:""
          }
        ]
      }
    ],
    zy:{
      pf:"9.99",
      nd:"高级",
      date:"17小时30分钟",
      renshu:"5524",
      qh:"995565665",
      yx:"488907729@qq.com",
      zlxz:"http:www.baidu.com",
      tacher:{
         image:"https://ts1.cn.mm.bing.net/th?id=OIP-C.AX66Lftteq7tfSSy_xlwwgAAAA&w=99&h=100&c=8&rs=1&qlt=90&o=6&dpr=1.25&pid=3.1&rm=2",
         name:"老师昵称",
         zw:"老师职位",
         p:"老师简介",
         list:[
           {
            image:"https://ts1.cn.mm.bing.net/th?id=OIP-C.AX66Lftteq7tfSSy_xlwwgAAAA&w=99&h=100&c=8&rs=1&qlt=90&o=6&dpr=1.25&pid=3.1&rm=2",
            title:"boot 打造企业微信",
            jd:"284.00",
            rs:"6689",
            flg:false
           }
         ]

      }
    },

  }
}
}
</script>

<style>

</style>
