<template>
  <div class="qa-container clearfix">
    <div class="qa-container-left minheight" id="pannel-list">
      <div class="js-searchblock">
        <div class="searchblock" id="js-searchblock">
          <span class="js-total-question">
            共<em class="js-total">2475</em>问题，
            <em class="js-total-answer">3492</em>回答
          </span>
          <div class="search-outer-bg">
            <div class="search-outer">
              <div class="search-inner">
                <input
                  type="text"
                  placeholder="请输入你的问题"
                  class="js-search-input text-input"
                />
                <i
                  class="imv2-close_c search-input-close"
                  style="display: none"
                ></i>
                <div class="search-input-btn js-search-qa">
                  <i class="imv2-search"></i>
                </div>
              </div>
              <a
                class="search-add-btn js-question-btn"
                href="#/learn/addquestion?cid=187&amp;mid=24123&amp;content=#"
                target="_blank"
                ><i class="imv2-yuanwen"></i>提问</a
              >
              <div class="search-relative js-search-relative"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="newquesblock" id="newquesitem">
        <div class="wenda-tab">
          <a data-cate="hot" href="#/learn/qa/187.html?cate=hot" class="onactive"
            >最热</a
          >
        </div>
      </div>
      <div class="newqueslistblock">
        <div id="qa-list">
          <div class="newquse-item js-click-result">
            <input
              type="hidden"
              class="inputHidden"
              data-cid="187"
              data-qid="vQW1lPEVmZqYyE9A"
            />
            <a
              target="_blank"
              href="#/learn/questiondetail/vQW1lPEVmZqYyE9A.html"
              class="nwenda-box"
            >
              <img
                src="https://tse3-mm.cn.bing.net/th/id/OIP-C.0oyv3jNduArxgMSdkYE11QAAAA?w=203&h=203&c=7&r=0&o=5&dpr=1.25&pid=1.7"
                class="wendahead"
                width="32"
                height="32"
              />
              <div class="nwenda-con">
              <h3 class="gold">
                  老师，你好，为何我使用的2.0.5的版本，按照视频中操作添加的zuul项目，通过路由访问其他组件的controller，一直报超时，配置上是不是有什么不同的？？
                  <span class="top">置顶</span>
                </h3>
                <p class="desc">
                  <span class="answername">廖师兄：</span
                  >同学你好，非常理解你想用最新版的心情。课程录制时用的是M2，此版本与最新版有不少的差别，你现在这个问题只是其中的一个，为了顺利完成课程，你先保持和课程中一样的版本。课程的最后，教了如何把SpringCloud的各个组件升级到最新版。
                </p>
              </div>
            </a>
            <div class="footer-info">
              <div class="info-l">
                <span class="viewnum"><i class="imv2-visibility"></i>1324</span>
                <span class="provenum"><i class="imv2-thumb_up"></i>0</span>
                <span class="replynum"><i class="imv2-msg"></i>7</span>
                <div class="headimg">
                  <img
                    src="//img.mukewang.com/user/5a9fd02e00018e3308000751-100-100.jpg"
                    data-index="4559066"
                  />
                </div>
                <a
                  href="//coding.imooc.com/lesson/187.html#mid=12202"
                  target="_blank"
                  class="charpterbtn"
                  >8-2 Zuul：路由转发，排除和自定义</a
                >
              </div>
              <div class="info-r">2018-09-18 16:32:29</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 问答列表模板 -->

      <div class="page pagination js-list-page">
        <div class="page">
          <span class="disabled_page">首页</span
          ><span class="disabled_page">上一页</span
          ><a href="javascript:void(0)" class="active">1</a
          ><a href="187.html?cate=hot&amp;page=2">2</a
          ><a href="187.html?cate=hot&amp;page=3">3</a
          ><a href="187.html?cate=hot&amp;page=4">4</a
          ><a href="187.html?cate=hot&amp;page=5">5</a
          ><a href="187.html?cate=hot&amp;page=6">6</a
          ><a href="187.html?cate=hot&amp;page=7">7</a
          ><a href="187.html?cate=hot&amp;page=2">下一页</a
          ><a href="187.html?cate=hot&amp;page=124">尾页</a>
        </div>
      </div>
    </div>
    <div class="qa-container-right">
      <div class="wrap-r">
        <!--我的模块-->
        <div class="myblock borderstyle">
          <a
            href="#/learn/myrelated?cid=187&amp;cate=myquestion"
            target="_self"
            class="item"
            ><i class="imv2-yuanwen"></i>我的提问<em class="num">1</em></a
          >
          <a
            href="#/learn/myrelated?cid=187&amp;cate=myanswer"
            target="_self"
            class="item"
            ><i class="imv2-nav_note"></i>我的回答<em class="num">0</em></a
          >
          <a
            href="#/learn/myrelated?cid=187&amp;cate=mycomment"
            target="_self"
            class="item"
            ><i class="imv2-textsms"></i>我的回复<em class="num">3</em></a
          >
        </div>
        <!--讲师模块-->
        <div class="teacherblock borderstyle">
          <div class="teacherhead clearfix">
            <a href="" target="_blank"
              ><img
                src="https://tse3-mm.cn.bing.net/th/id/OIP-C.0oyv3jNduArxgMSdkYE11QAAAA?w=203&h=203&c=7&r=0&o=5&dpr=1.25&pid=1.7"
                class="head"
            /></a>
            <div class="tinfo">
              <a href="" target="_blank"
                ><h3 class="nickname">
                  <span>廖师兄</span><i class="icon-imooc"></i></h3
              ></a>
              <p class="job">JAVA开发工程师</p>
            </div>
          </div>
          <div class="teacherinfo">
            <h3>讲师分享</h3>
            <div class="tewlist">
              <a
                href="#/learn/questiondetail/V2104YQpmaLPmxQw.html"
                target="_blank"
                class="item"
              >
                <p class="title">【必看】提问须知</p>
                <p class="footinfo">
                  <span><i class="imv2-visibility"></i>171</span
                  ><span><i class="imv2-msg"></i>1</span>
                </p>
              </a>
              <a
                href="#/learn/questiondetail/kxNyDPbWERaYGgb7.html"
                target="_blank"
                class="item"
              >
                <p class="title">网易的不好用了，用阿里云吧</p>
                <p class="footinfo">
                  <span><i class="imv2-visibility"></i>179</span
                  ><span><i class="imv2-msg"></i>1</span>
                </p>
              </a>
              <a
                href="#/learn/questiondetail/Dg8m5XZeVd7YWa4B.html"
                target="_blank"
                class="item"
              >
                <p class="title">[已补充]实战多模块</p>
                <p class="footinfo">
                  <span><i class="imv2-visibility"></i>142</span
                  ><span><i class="imv2-msg"></i>1</span>
                </p>
              </a>
            </div>
            <a
              href=""
              target="_blank"
              class="moretearch"
              >更多讲师课程</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style>


.elevator a .elevator-num {
  background-color: #f01414;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  position: absolute;
  top: 3px;
  width: 18px;
  height: 18px;
  line-height: 16px;
  right: -9px;
}

.elevator .elevator-dist i {
  display: inline-block;
  width: 18px;
  height: 20px;
  background: url(//www.imooc.com/static/img/index/redPacket.png) no-repeat;
  background-size: 100% 100%;
  background-position: top center;
  margin: 2px 0;
}

.elevator .elevator-app:hover i,
.elevator .elevator-dist:hover i,
.elevator .elevator-faq:hover i,
.elevator .elevator-msg:hover i,
.elevator .elevator-top:hover i,
.elevator .elevator-weixin:hover i {
  display: none;
}

.elevator .elevator-app:hover span,
.elevator .elevator-dist:hover span,
.elevator .elevator-faq:hover span,
.elevator .elevator-msg:hover span,
.elevator .elevator-top:hover span,
.elevator .elevator-weixin:hover span {
  display: inline-block;
}

.elevator .elevator-app-box {
  position: absolute;
  width: 172px;
  height: 212px;
  bottom: -10px;
  right: 52px;
  -webkit-transition: opacity 0.25s, transform 0.3s;
  -moz-transition: opacity 0.25s, transform 0.3s;
  transition: opacity 0.25s, transform 0.3s;
  opacity: 0;
  max-width: 0;
  -webkit-transform: scale(0.01);
  -ms-transform: scale(0.01);
  transform: scale(0.01);
  -webkit-transform-origin: 100% 95%;
  -ms-transform-origin: 100% 95%;
  transform-origin: 100% 95%;
  background: url(https://static.mukewang.com/static/img/common/elevator.png?t=0705)
    no-repeat 0 -220px;
}

.elevator .elevator-app:hover .elevator-app-box {
  display: block;
  visibility: visible;
  opacity: 1;
  max-width: none;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.elevator .elevator-weixin-box {
  position: absolute;
  width: 172px;
  height: 212px;
  bottom: -10px;
  right: 52px;
  -webkit-transition: opacity 0.25s, transform 0.3s;
  -moz-transition: opacity 0.25s, transform 0.3s;
  transition: opacity 0.25s, transform 0.3s;
  opacity: 0;
  max-width: 0;
  -webkit-transform: scale(0.01);
  -ms-transform: scale(0.01);
  transform: scale(0.01);
  -webkit-transform-origin: 100% 95%;
  -ms-transform-origin: 100% 95%;
  transform-origin: 100% 95%;
  background: url(https://static.mukewang.com/static/img/common/elevator.png?t=0705)
    no-repeat 0 0;
}

.elevator .elevator-weixin:hover .elevator-weixin-box {
  display: block;
  visibility: visible;
  opacity: 1;
  max-width: none;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

a {
  outline: 0;
}

a:active {
  star: expression(this.onFocus=this.blur());
}

a,
button,
input {
  outline: 0;
}

button::-moz-focus-inner {
  border: 0;
}

input::-moz-focus-inner {
  border: 0;
}

.autowrap {
  word-wrap: break-word;
  word-break: break-all;
}

.layer-usercard {
  position: absolute;
  z-index: 999;
  width: 370px;
  height: 165px;
  background: #fff;
  box-shadow: 0 2px 2px #999;
}

.layer-usercard .arrow {
  position: absolute;
  left: 65px;
  top: -11px;
  width: 19px;
  height: 11px;
  background: url(/static/module/common/img/dot_usercard.png);
}

.layer-usercard-header {
  height: 110px;
  background: #2a2c2e;
}

.layer-usercard-header .avatar img {
  border-radius: 50px;
  left: 21px;
  position: absolute;
  top: 21px;
  border: 3px solid #7f8082;
}

.layer-usercard-header dd,
.layer-usercard-header dt {
  float: right;
  width: 245px;
  padding-right: 20px;
  color: #fff;
}

.layer-usercard-header dt {
  padding-top: 20px;
  font-size: 18px;
}

.layer-usercard-header dd {
  font-size: 12px;
}

.layer-usercard-info ul {
  overflow: hidden;
  padding-top: 16px;
  height: 32px;
  font-size: 16px;
  color: #364247;
  position: relative;
}

.layer-usercard-info li {
  float: left;
  padding: 0 22px;
  border-right: 1px solid #d9d9d9;
}

.layer-usercard-info span {
  color: #969b9e;
  font-size: 12px;
}

.layer-usercard-info li.noborder {
  border: 0;
}

li.layer-usercard-medal {
  padding: 0;
  top: 12px;
  right: 10px;
  width: 117px;
  position: absolute;
  border: 0;
}

.layer-usercard-medal a {
  width: 32px;
  height: 32px;
  float: left;
  margin-right: 5px;
  border-radius: 1px;
  overflow: hidden;
}

.send-area-result {
  background: #fff;
  box-shadow: 0 4px 8px rgba(7, 17, 27, 0.2);
  -moz-box-shadow: 0 4px 8px rgba(7, 17, 27, 0.2);
  -webkit-box-shadow: 0 4px 8px rgba(7, 17, 27, 0.2);
  position: absolute;
  top: 48px;
  left: 0;
  width: inherit;
  border-radius: 0 0 4px 4px;
}

.send-area-result dt {
  font-size: 12px;
  color: #b7bbbf;
  line-height: 40px;
  height: 40px;
  padding: 0 10px;
}

.send-area-result dd {
  height: 40px;
  line-height: 40px;
}

.send-area-result dd a.questiontitle {
  border: none;
  font-size: 14px;
  color: #93999f;
  width: inherit;
  text-align: left;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  display: block;
}

.oncurr {
  color: #07111b;
  background: #f3f5f7;
}

.oncurr a.questiontitle {
  color: #07111b !important;
}

.send-area-result dd a.questiontitle em,
.send-area-result dd a.questiontitle i {
  font-style: normal;
  font-size: 12px;
  margin-left: 8px;
}

.send-area-result dd a.questiontitle em {
  color: #b7bbbf;
}

.send-area-result dd a.questiontitle i {
  color: #00b33b;
}

html.holding {
  height: 100%;
  overflow: hidden;
}

body.slide-left {
  -webkit-transform: translate(-130px, 0);
  -ms-transform: translate(-130px, 0);
  transform: translate(-130px, 0);
  margin-right: -130px;
  padding-right: 130px;
  height: 100%;
  overflow: hidden;
}

body.slide-left:after {
  left: -130px;
  visibility: visible;
  opacity: 0.3;
  z-index: 1002;
}

@media screen and (max-width: 820px) {
  .elevator {
    display: none !important;
  }

  .navbar-toggle {
    position: relative;
    float: right;
    border: 0;
    color: #fff;
    margin-top: 10px;
    margin-right: -5px;
    outline: 0;
    padding: 5px 10px;
  }

  .navbar-toggle,
  .navbar-toggle:focus,
  .navbar-toggle:hover {
    background: 0 0;
  }

  .navbar-toggle .sz-list {
    color: #fff;
    font-size: 32px;
  }
}

@media (min-width: 1600px) {
  #new_header .new-header {
    width: 1446px;
    max-width: 1446px;
  }
}

.article-head .user-card-item img {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  vertical-align: middle;
}

#sz-banner {
  position: relative;
  height: 188px;
  background-image: linear-gradient(-90deg, #07111b 0, rgba(7, 17, 27, 0) 100%);
}

#sz-banner .info-bg {
  overflow: hidden;
  z-index: 0;
}

#sz-banner .info-bg,
#sz-banner .info-bg .cover-canvas,
#sz-banner .info-bg .cover-img-wrap,
#sz-banner .info-bg .cover-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 188px;
}

#sz-banner .info-bg .cover-img-wrap {
  z-index: 0;
  overflow: hidden;
}

#sz-banner .info-bg .cover-img-wrap img {
  position: absolute;
  left: 0;
  top: 0;
}

#sz-banner .wrap {
  width: 1152px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

#sz-banner .sz-banner-left,
#sz-banner .sz-banner-right {
  height: 188px;
  position: relative;
}

#sz-banner .sz-banner-left {
  float: left;
  width: 100%;
}

#sz-banner .sz-banner-right {
  position: absolute;
  right: 0;
  top: 0;
  width: 304px;
}

#sz-banner .course-notice {
  position: absolute;
  top: 24px;
  right: 0;
  width: 68px;
  height: 32px;
  background: rgba(255, 153, 0, 0.8);
  box-shadow: 0 2px 4px 0 rgba(7, 17, 27, 0.2);
  border-radius: 16px;
  padding: 6px 12px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s all linear;
}

#sz-banner .course-notice:hover {
  background: #f90;
}

#sz-banner .course-notice i,
#sz-banner .course-notice span {
  float: left;
  color: #fff;
  line-height: 20px;
}

#sz-banner .course-notice i {
  font-size: 16px;
  margin-right: 4px;
}

#sz-banner .course-notice span {
  font-size: 12px;
}

#sz-banner .course-notice .course-new-notice {
  display: none;
}

#sz-banner .course-notice.open {
  width: 304px;
  height: 140px;
  padding: 16px 24px;
  background: #f90;
  box-shadow: 0 8px 16px 0 rgba(7, 17, 27, 0.2);
  border-radius: 8px;
  z-index: 1;
  cursor: default;
}

#sz-banner .course-notice.open i {
  font-size: 20px;
}

#sz-banner .course-notice.open span {
  font-size: 14px;
}

#sz-banner .course-notice.open .course-new-notice {
  display: block;
}

#sz-banner .course-notice.open p {
  font-size: 12px;
  color: #fff;
  line-height: 24px;
  margin: 8px 0;
}

#sz-banner .course-notice.open .al {
  float: left;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 24px;
}

#sz-banner .course-notice.open .al:hover {
  color: #fff;
}

#sz-banner .course-notice.open .ar {
  float: right;
  color: #fff;
  line-height: 24px;
}

#sz-banner .course-notice.open .ar span {
  float: left;
  font-size: 12px;
  margin-right: 4px;
}

#sz-banner .course-notice.open .ar i {
  float: left;
  font-size: 16px;
}

#sz-banner .crumbs {
  padding-top: 16px;
}

#sz-banner .crumbs a,
#sz-banner .crumbs i,
#sz-banner .crumbs span {
  float: left;
  font-size: 12px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
}

#sz-banner .crumbs a:hover {
  color: #fff;
}

#sz-banner .crumbs i {
  margin: 0 4px;
  font-style: normal;
  width: 6px;
  text-align: center;
}

#sz-banner .course-title {
  padding-top: 5px;
  font-size: 32px;
  color: #fff;
  height: 45px;
  line-height: 36px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-word;
}

#sz-banner .course-title a {
  font-size: 32px;
  color: #fff;
}

#sz-banner .shizhan-nav {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
}

#sz-banner .shizhan-nav a {
  float: left;
  width: 124px;
  height: 60px;
  padding: 12px 24px 16px;
  box-sizing: border-box;
}

#sz-banner .shizhan-nav a:not(:last-child) {
  margin-right: 72px;
}

#sz-banner .shizhan-nav a:hover i,
#sz-banner .shizhan-nav a:hover span {
  color: #fff;
}

#sz-banner .shizhan-nav a.on {
  background: #f8fafc;
  border-radius: 4px 4px 0 0;
}

#sz-banner .shizhan-nav a.on i {
  color: #f01414;
}

#sz-banner .shizhan-nav a.on span {
  color: #2b333b;
}

#sz-banner .shizhan-nav i,
#sz-banner .shizhan-nav span {
  color: rgba(255, 255, 255, 0.6);
  line-height: 32px;
}

#sz-banner .shizhan-nav i {
  float: left;
  font-size: 32px;
}

#sz-banner .shizhan-nav span {
  float: right;
  font-size: 16px;
}

#sz-banner .wrap-other {
  position: relative;
  height: 150px;
}

#sz-banner .wrap-other img {
  display: block;
  width: 100%;
  height: 150px;
}

#sz-banner .wrap-other .user-info {
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -600px;
  width: 1000px;
  padding-left: 168px;
  height: 100%;
}

#sz-banner .wrap-other .user-info a {
  position: absolute;
  left: 0;
  bottom: -20px;
  width: 132px;
  height: 132px;
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  overflow: hidden;
}

#sz-banner .wrap-other .user-info img {
  width: 132px;
  height: 132px;
}

#sz-banner .wrap-other .user-info h3 {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  line-height: 40px;
  margin-top: 48px;
  margin-bottom: 18px;
}

#sz-banner .wrap-other .user-info span {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  opacity: 0.5;
}

#sz-banner .wrap-other .user-info i {
  font-style: normal;
  margin: 0 20px;
  color: #fff;
  opacity: 0.2;
}

#sz-banner .wrap-other .user-info em {
  margin-left: 8px;
}

.compatible-contianer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-width: 800px;
  height: 30px;
  line-height: 30px;
  background: url(/static/module/common/img/iebg.gif) repeat-x;
  z-index: 999999;
}

.cpt-ct {
  color: #363636;
  font-size: 12px;
  text-align: center;
}

.cpt-ct i {
  display: inline-block;
  width: 12px;
  height: 14px;
  vertical-align: -2px;
  margin-right: 5px;
  background: url(/static/module/common/img/iefixed-sprite.png) no-repeat 0 0;
}

.cpt-ct a {
  color: #39b94e;
}

.cpt-ct a:hover {
  text-decoration: underline;
}

.cpt-handle {
  position: absolute;
  right: 20px;
  top: 0;
  font-size: 12px;
  line-height: 27px;
}

.cpt-handle a {
  display: inline-block;
  vertical-align: middle;
}

.cpt-handle .cpt-agin {
  color: #656e73;
  margin-right: 5px;
}

.cpt-handle .cpt-agin:hover {
  color: #363d40;
}

.cpt-close {
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  border-radius: 50%;
  transition: 0.3s;
}

.cpt-close:hover {
  background-color: #fc8800;
}

.cpt-close i {
  display: inline-block;
  height: 8px;
  width: 8px;
  vertical-align: 1px;
  background: url(/static/module/common/img/iefixed-sprite.png) no-repeat 0 -24px;
}

.cpt-close:hover i {
  background-position: 0 -42px;
}

.identity {
  font-size: 16px;
  margin-left: 5px;
  color: red;
  position: relative;
  top: 3px;
  display: inline-block;
}

.sz-jxsjs {
  background: url(/static/module/common/img/role_icon.png) no-repeat 0 0;
  width: 16px;
  height: 16px;
}

.sz-zhuj {
  background: url(/static/module/common/img/role_icon.png) no-repeat 0 -25px;
  width: 16px;
  height: 16px;
}

.project-qrcode {
  position: relative;
  float: right;
  width: 30px;
  height: 15px;
  color: #787d82;
}

.project-qrcode .sz-qrcode {
  vertical-align: -3px;
  color: #b5b9bc;
  cursor: pointer;
}

.project-qrcode .sz-qrcode:before {
  content: "\e903";
  font-size: 18px;
}

.project-qrcode .sz-qrcode:hover {
  color: #787d82;
}

div.qrcode-box {
  display: none;
  padding: 30px;
  background: #fff;
  width: 200px;
  height: 250px;
  position: absolute;
  z-index: 99;
  top: 42px;
  right: -110px;
  text-align: center;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.3);
}

div.qrcode-box .qrcode-dot {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -8px;
  left: 120px;
  background: #fff;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  box-shadow: 7px 5px 16px 0 rgba(0, 0, 0, 0.3);
}

div.qrcode-box .qrcode-dot-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 260px;
  height: 30px;
  background: #fff;
}

div.qrcode-box .qrcode-img {
  width: 164px;
  height: 164px;
  margin: 0 auto;
}

div.qrcode-box p {
  float: none !important;
  padding: 30px 0 10px;
  font-size: 18px;
  color: #1c1f21;
  line-height: 16px;
  margin: 0;
}

div.qrcode-box span {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #b5b9bc;
  line-height: 150%;
}

.col-aside .divider {
  margin: 28px 0;
  height: 0;
  overflow: hidden;
  border-bottom: 1px solid #d9dde1;
}

.newcomer {
  width: 100%;
  height: 136px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  background-image: url(/static/module/img/newcomer.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.newcomer i {
  position: absolute;
  right: 20px;
  bottom: 48px;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.6);
}

.newcomer i:hover {
  color: #fff;
}

.lotterycomer {
  width: 100%;
  height: 50px;
  background-image: url(/static/module/img/lottery-top-02.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
  position: relative;
  z-index: 9999;
}

.lottery_mask {
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: rgba(7, 17, 27, 0.6);
}

.lottery_modal {
  position: fixed;
  z-index: 999;
  left: 50%;
  margin-left: -240px;
  top: 50%;
  margin-top: -220px;
  width: 480px;
  height: 440px;
  background-repeat: no-repeat;
  background-size: cover;
}

.lottery_modal i {
  float: right;
  color: #fff;
  margin: 22px;
  cursor: pointer;
  font-size: 32px;
}

.lottery_modal a.btn-lottery {
  display: block;
  width: 100%;
  height: 100%;
}

#septemberSchool {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(7, 17, 27, 0.6);
}

#septemberSchool .popup_box {
  position: fixed;
  color: #fff;
  width: 530px;
  height: 348px;
  background: url(/static/module/img/septemberSchool_bg.png);
  left: 50%;
  top: 50%;
  margin-left: -245px;
  margin-top: -210px;
}

#septemberSchool .popup_box .imv2-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
  cursor: pointer;
}

#septemberSchool .popup_box .inner_text {
  position: relative;
  margin: 90px 97px;
  width: 296px;
  height: 162px;
  text-align: center;
}

#septemberSchool .popup_box .inner_text h1 {
  font-size: 32px;
  line-height: 32px;
  font-weight: 600;
}

#septemberSchool .popup_box .inner_text h2 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  margin-top: 6px;
}

#septemberSchool .popup_box .inner_text h4 {
  font-size: 18px;
  line-height: 49px;
  margin-top: 6px;
}

#septemberSchool .popup_box .inner_text h3 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 6px;
  line-height: 18px;
  margin-bottom: 9px;
}

#septemberSchool .popup_box .inner_text .clock {
  text-align: center;
  width: 100%;
  height: 48px;
  padding: 7px 0;
  box-sizing: border-box;
  font-size: 20px;
  display: block;
  position: absolute;
  bottom: -48px;
  left: 0;
}

#septemberSchool .popup_box .inner_text .clock span {
  display: inline-block;
  font-size: 22px;
  color: #383d42;
  background: #fff;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 4px;
  margin: 0 6px;
}

#septemberSchool .popup_box .inner_text .get-btn {
  display: inline-block;
  background-image: linear-gradient(270deg, #fac200 0, #f28f00 100%);
  border-radius: 26px;
  font-size: 20px;
  color: #fff;
  line-height: 20px;
  padding: 12px 34px;
  position: absolute;
  bottom: -166px;
  left: 50%;
  margin-left: -74px;
}

#septemberSchool_finish {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(7, 17, 27, 0.6);
}

#septemberSchool_finish .popup_box {
  position: fixed;
  width: 900px;
  height: 450px;
  left: 50%;
  top: 50%;
  margin-left: -450px;
  margin-top: -225px;
  padding: 24px 22px;
  background: #fff;
  border: 1px solid #979797;
  box-shadow: 0 8px 16px 0 rgba(28, 31, 33, 0.2);
  border-radius: 12px;
  box-sizing: border-box;
}

#septemberSchool_finish .popup_box .imv2-close {
  position: absolute;
  top: 24px;
  right: 32px;
  font-size: 24px;
  color: #b2b8bd;
  cursor: pointer;
}

#septemberSchool_finish .popup_box .imv2-close:hover {
  color: #6d6d6d;
}

#septemberSchool_finish .popup_box .title {
  font-size: 16px;
  color: #1c1f21;
  text-align: center;
  line-height: 24px;
  font-weight: 600;
}

#septemberSchool_finish .popup_box .content {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 32px;
}

#septemberSchool_finish .popup_box .content .item {
  height: 318px;
  border-radius: 12px;
}

#septemberSchool_finish .popup_box .content .item.coupon-box {
  width: 251px;
  background: #f2f2f2;
  margin-left: 24px;
  overflow: hidden;
}

#septemberSchool_finish .popup_box .content .item.coupon-box h3 {
  font-size: 14px;
  color: #fff;
  line-height: 18px;
  padding: 14px 0;
  text-align: center;
}

#septemberSchool_finish .popup_box .content .item.coupon-box h3.cc {
  background: #f53d3d;
}

#septemberSchool_finish .popup_box .content .item.coupon-box h3.read {
  background: #37f;
}

#septemberSchool_finish .popup_box .content .item.coupon-box .coupon {
  display: block;
  width: 214px;
  height: 106px;
  margin: 16px auto;
  background-size: contain;
  background-position: center;
}

#septemberSchool_finish .popup_box .content .item.coupon-box .coupon.a,
#septemberSchool_finish .popup_box .content .item.coupon-box .coupon.b {
  background-image: url(/static/module/img/september_coupon_bg1.png);
}

#septemberSchool_finish .popup_box .content .item.coupon-box .coupon.c,
#septemberSchool_finish .popup_box .content .item.coupon-box .coupon.d {
  background-image: url(/static/module/img/september_coupon_bg2.png);
}

#septemberSchool_finish .popup_box .content .item.intro {
  margin-left: 36px;
}

#septemberSchool_finish .popup_box .content .item.intro .text {
  margin-top: 50px;
  margin-bottom: 36px;
}

#septemberSchool_finish .popup_box .content .item.intro .text h3 {
  font-size: 14px;
  color: #545c63;
  line-height: 20px;
  font-weight: 600;
}

#septemberSchool_finish .popup_box .content .item.intro .text p {
  font-size: 12px;
  color: #545c63;
  line-height: 20px;
  margin-top: 20px;
}

#septemberSchool_finish .popup_box .content .item.intro .sep-coupon-btn {
  display: inline-block;
  width: 126px;
  height: 36px;
  font-size: 14px;
  color: #fff;
  line-height: 35px;
  background: #383d42;
  border-radius: 26px;
  text-align: center;
}

#septemberSchool_finish
  .popup_box
  .content
  .item.intro
  .sep-coupon-btn:last-child {
  margin-left: 12px;
}

.word-break {
  word-break: break-all;
  word-wrap: break-word;
}

.keep-word {
  white-space: nowrap;
  word-break: keep-all;
}

.display_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.display_flex > * {
  display: block;
}

.display_inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.display_inline-flex > * {
  display: block;
}

.flex-direction_column {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.justify-content_flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.justify-content_flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.justify-content_flex-justify {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.align-items_flex-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.align-items_flex-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.align-items_center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.align-items_flex-justify {
  -webkit-box-align: justify;
  -ms-flex-align: justify;
  -webkit-align-items: space-between;
  align-items: space-between;
}

.align-items_baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.flex_auto {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  -webkit-flex: auto;
  flex: auto;
}

.flex_1 {
  width: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}

.order_2 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}

.order_3 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
}

.relative {
  position: relative;
}

.course-sidebar-title h3 .span {
  float: left;
  font-size: 16px;
  color: #07111b;
  line-height: 24px;
}

.course-sidebar-title h3 a {
  float: right;
  font-size: 12px;
  color: #93999f;
  line-height: 24px;
}

.course-sidebar-title h3 a span {
  margin-right: 4px;
}

.course-sidebar-title h3 a i,
.course-sidebar-title h3 a span {
  float: left;
  font-style: normal;
}

.course-sidebar-title h3 a:hover {
  color: #07111b;
}

.course-sidebar {
  margin-bottom: 16px;
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
}

.course-sidebar .href_btn {
  display: block;
  width: 160px;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  transition: 0.3s all linear;
}

.course-sidebar.evaluate {
  margin: 12px 0 16px;
  height: 256px;
  padding: 24px 4px;
  background-color: #eff2f5;
  position: relative;
}

.course-sidebar.evaluate h2 {
  font-size: 16px;
  color: #07111b;
  text-align: center;
  line-height: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}

.course-sidebar.evaluate h3 {
  font-size: 32px;
  color: #fa0;
  text-align: center;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 8px;
}

.course-sidebar.evaluate .course-score {
  margin-bottom: 8px;
}

.course-sidebar.evaluate .course-score .stars {
  display: block;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.course-sidebar.evaluate .course-score i {
  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 153, 0, 0.4);
}

.course-sidebar.evaluate .course-score i.on {
  color: #f90;
}

.course-sidebar.evaluate .course-score em {
  position: relative;
}

.course-sidebar.evaluate .course-score em i {
  color: rgba(255, 153, 0, 0.4);
}

.course-sidebar.evaluate .course-score .sz-star-h {
  color: #f90 !important;
  position: absolute;
  top: 0;
  left: 0;
}

.course-sidebar.evaluate .href_btn {
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 153, 0, 0.8);
}

.course-sidebar.evaluate .href_btn:hover {
  background: #f90;
}

.course-sidebar.evaluate .href_btn.look {
  border: 1px solid #f90;
  background-color: #eff2f5;
  color: #f90;
}

.course-sidebar.evaluate .href_btn.look:hover {
  background: #f90;
  color: #fff;
}

.course-sidebar.evaluate p {
  width: 256px;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  color: #93999f;
  line-height: 20px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #d9dde1;
  text-align: center;
  font-weight: 300;
}

.course-sidebar.evaluate p a {
  color: rgba(255, 153, 0, 0.6);
}

.course-sidebar.evaluate p a:hover {
  color: #f90;
}

.course-sidebar.evaluate dl {
  float: left;
  width: 96px;
  margin-right: 4px;
  text-align: center;
}

.course-sidebar.evaluate dl:last-child {
  margin-right: 0;
}

.course-sidebar.evaluate dt {
  font-size: 12px;
  color: #07111b;
  line-height: 16px;
  margin-bottom: 2px;
}

.course-sidebar.evaluate dd {
  font-size: 12px;
  color: #4d555d;
  line-height: 16px;
  font-weight: 300;
}

.course-sidebar.evaluate .strategy {
  display: none;
  position: absolute;
  top: 42px;
  right: -42px;
  width: 230px;
  height: 102px;
  background: #f90;
  box-shadow: 0 2px 4px 0 rgba(7, 17, 27, 0.2);
  border-radius: 2px;
  padding: 12px 16px 12px;
  font-size: 12px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  box-sizing: border-box;
}

.course-sidebar.evaluate .strategy .sanjiao {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #f90;
  position: absolute;
  bottom: -20px;
  left: 87px;
}

.course-sidebar.porject {
  background-color: #eff2f5;
  margin-bottom: 16px;
  text-align: center;
  position: relative;
}

.course-sidebar.porject .item {
  position: relative;
}

.course-sidebar.porject h4 {
  font-size: 14px;
  color: #07111b;
  margin-bottom: 2px;
  vertical-align: middle;
}

.course-sidebar.porject h4 i {
  font-size: 14px;
  color: rgba(240, 20, 20, 0.8);
  margin-left: 2px;
  cursor: pointer;
}

.course-sidebar.porject .token-intro {
  display: none;
  font-size: 12px;
  color: #545c63;
  line-height: 40px;
  width: 352px;
  height: 40px;
  text-align: center;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(28, 31, 33, 0.2);
  border-radius: 8px;
  position: absolute;
  right: 90px;
  top: -9px;
}

.course-sidebar.porject .token-box {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 6px;
}

.course-sidebar.porject .token-box a:first-child {
  font-size: 14px;
  color: #1c1f21;
  line-height: 30px;
  cursor: default;
  font-weight: 700;
  text-align: center;
  width: 208px;
}

.course-sidebar.porject .token-box a:first-child.endline {
  text-decoration: line-through;
  color: #b2b8bd;
  font-weight: 400;
}

.course-sidebar.porject .token-box a:last-child {
  background: #ef4041;
  font-size: 14px;
  color: #fff;
  line-height: 30px;
  text-align: center;
  width: 48px;
}

.course-sidebar.porject .token-box a:last-child.disabled {
  cursor: not-allowed;
  background: rgba(242, 13, 13, 0.4);
}

.course-sidebar.porject .token-tip-box {
  margin-top: 8px;
  text-align: center;
}

.course-sidebar.porject .token-tip-box .tip {
  font-size: 12px;
  color: #93999f;
  line-height: 17px;
}

.course-sidebar.porject .token-tip-box .new-token {
  font-size: 12px;
  color: rgba(240, 20, 20, 0.8);
  line-height: 17px;
  margin-left: 13px;
}

.course-sidebar.porject .project_href a {
  font-size: 12px;
  color: rgba(240, 20, 20, 0.6);
  line-height: 20px;
  cursor: default;
}

.course-sidebar.porject .project_href a:hover {
  color: #f01414;
}

.course-sidebar.porject p {
  font-size: 12px;
  color: #93999f;
  line-height: 20px;
  font-weight: 300;
}

.course-sidebar.porject p span {
  display: inline-block;
  margin-left: 2px;
}

.course-sidebar.porject .item:first-child {
  margin-top: 0;
}

.course-sidebar.porject .item:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.course-sidebar.porject .item {
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #d9dde1;
}

.course-sidebar.porject .item .title {
  font-size: 16px;
  font-weight: 700;
  color: #07111b;
  text-align: center;
  margin-bottom: 8px;
}

.course-sidebar.porject .href_btn {
  background: rgba(240, 20, 20, 0.6);
  margin: 8px auto;
}

.course-sidebar.porject .href_btn:hover {
  background: #f01414;
}

.course-sidebar.porject .href_btn.disabled {
  cursor: default;
  background: #d9dde1;
}

.course-sidebar.porject .qrcode-box {
  top: 82px;
  right: 22px;
}

.course-sidebar.coupon {
  background-color: #eff2f5;
  margin-bottom: 20px;
  text-align: center;
}

.course-sidebar.coupon h4 {
  font-size: 16px;
  color: #f01414;
  line-height: 24px;
  margin-bottom: 4px;
}

.course-sidebar.coupon h4 span {
  font-weight: 700;
  margin-right: 4px;
}

.course-sidebar.coupon p {
  font-size: 12px;
  color: #93999f;
  line-height: 20px;
  margin-bottom: 12px;
}

.course-sidebar.coupon .href_btn {
  width: 104px;
  background: rgba(240, 20, 20, 0.6);
  margin-left: auto;
  margin-right: auto;
}

.course-sidebar.coupon .href_btn:hover {
  background: #f01414;
}

.lecturer-instr {
  background: #eff2f5;
  border-radius: 8px;
  width: 304px;
  box-sizing: border-box;
  padding: 24px 36px 36px;
  margin-bottom: 48px;
}

.lecturer-instr .lecturer-head {
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto 4px;
  border-radius: 50%;
  overflow: hidden;
}

.lecturer-instr .lecturer-head img {
  width: 100%;
}

.lecturer-instr .lecturer-name {
  text-align: center;
  height: 28px;
}

.lecturer-instr .lecturer-name a {
  display: inline-block;
}

.lecturer-instr .lecturer-name span {
  font-size: 16px;
  color: #07111b;
  line-height: 28px;
  max-width: 180px;
  height: 28px;
  overflow: hidden;
}

.lecturer-instr .lecturer-name i,
.lecturer-instr .lecturer-name span {
  float: left;
}

.lecturer-instr .lecturer-name i {
  width: 14px;
  height: 14px;
  margin: 7px 0 0 8px;
}

.lecturer-instr .lecturer-title {
  font-size: 12px;
  color: #4d555d;
  line-height: 24px;
  text-align: center;
  margin-bottom: 12px;
}

.lecturer-instr p {
  font-size: 12px;
  color: #4d555d;
  line-height: 20px;
  font-weight: 300;
}

.lecturer-sz-recommend {
  display: none;
  width: 304px;
}

.lecturer-sz-recommend h3 {
  margin-bottom: 32px;
}

.lecturer-sz-recommend h3 .span {
  float: left;
  font-size: 16px;
  color: #07111b;
  line-height: 24px;
}

.lecturer-sz-recommend h3 a {
  float: right;
  font-size: 12px;
  color: #93999f;
  line-height: 24px;
}

.lecturer-sz-recommend h3 a span {
  margin-right: 4px;
}

.lecturer-sz-recommend h3 a i,
.lecturer-sz-recommend h3 a span {
  float: left;
}

.lecturer-sz-recommend h3 a:hover {
  color: #07111b;
}

.lecturer-course .lecturer-course-item {
  margin-top: 32px;
}

.lecturer-course .lecturer-course-item-l {
  float: left;
  width: 72px;
  height: 48px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 16px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.lecturer-course .lecturer-course-item-r {
  width: 216px;
  float: left;
}

.lecturer-course .course-item-title {
  font-size: 14px;
  color: #4d555d;
  line-height: 20px;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.lecturer-course .course-item-title:hover {
  color: #07111b;
}

.lecturer-course .course-info {
  margin-bottom: 4px;
}

.lecturer-course .course-info i,
.lecturer-course .course-info span {
  float: left;
  margin-right: 12px;
  font-size: 12px;
  color: #93999f;
  line-height: 20px;
}

.lecturer-course .course-info i {
  margin-right: 2px;
}

.lecturer-course .course-price span {
  float: left;
  font-size: 12px;
  color: #93999f;
  line-height: 20px;
  margin-right: 4px;
}

.lecturer-course .course-price .promotion {
  color: #f01414;
}

.lecturer-course .course-price .discount_name {
  background: rgba(240, 20, 20, 0.06);
  border-radius: 2px;
  color: rgba(240, 20, 20, 0.8);
  padding: 0 4px;
}

.moco-modal-layer {
  background-image: url(/static/module/learn/list/img/modal_bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.moco-modal-layer .moco-modal-title span {
  display: none;
}

.jiaQunCon {
  width: 372px;
  margin: 0 auto;
  position: relative;
  margin-top: 9px;
}

.jiaQunCon .qr-ma {
  position: absolute;
  left: 0;
  top: 0;
  width: 144px;
  height: 144px;
  overflow: hidden;
}

.jiaQunCon .text-con {
  position: absolute;
  right: 0;
  top: 0;
  width: 205px;
  text-align: left;
  padding-top: 12px;
}

.jiaQunCon .text-con h3 {
  font-size: 20px;
  color: #c80;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 16px;
}

.jiaQunCon .text-con p {
  font-size: 14px;
  color: #1c1f21;
  line-height: 17px;
  margin-bottom: 10px;
  word-break: break-all;
}

.jiaQunCon .text-con p a {
  color: #95b9ff;
}

.get-recom-coupon-box-blackout {
  display: none;
  background: rgba(7, 17, 27, 0.7);
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 901;
}

.get-recom-coupon-box {
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -188px;
  margin-left: -350px;
  z-index: 902;
}

.get-recom-coupon-box .getcoupon-box {
  background: 0 0;
  width: 716px;
  text-align: center;
}

.get-recom-coupon-box .getcoupon-box .top-title {
  text-align: center;
}

.get-recom-coupon-box .getcoupon-box .top-title h3 {
  font-size: 36px;
  line-height: 36px;
  color: #fff;
  margin-bottom: 24px;
}

.get-recom-coupon-box .getcoupon-box .introduce {
  width: 716px;
  height: 234px;
  margin-bottom: 15px;
  background: url(/static/module/evaluation/index/img/get-coupons2.png);
  background-size: cover;
}

.get-recom-coupon-box .getcoupon-box .introduce .left-box {
  margin-left: 88px;
  width: 216px;
  text-align: center;
}

.get-recom-coupon-box .getcoupon-box .introduce .left-box .big-price {
  margin-top: 72px;
  font-size: 48px;
  line-height: 48px;
  color: #fff;
}

.get-recom-coupon-box .getcoupon-box .introduce .left-box .big {
  margin-top: 88px;
  font-size: 64px;
  line-height: 64px;
}

.get-recom-coupon-box .getcoupon-box .introduce .left-box .use-range {
  margin-top: 12px;
  font-size: 24px;
  color: #fff;
  letter-spacing: 0;
  line-height: 24px;
}

.get-recom-coupon-box .getcoupon-box .introduce .coupon-del {
  width: 246px;
  margin-left: 42px;
  margin-top: 44px;
  text-align: left;
}

.get-recom-coupon-box .getcoupon-box .introduce .coupon-del .coupon-adjust {
  font-size: 16px;
  color: #07111b;
  letter-spacing: 0;
  line-height: 24px;
}

.get-recom-coupon-box .getcoupon-box .introduce .coupon-del .coupon-more {
  margin-top: 8px;
  font-size: 12px;
  color: #93999f;
  letter-spacing: 0;
  line-height: 16px;
}

.get-recom-coupon-box .getcoupon-box .introduce .coupon-del .go-my-coupon {
  border: 1px solid rgba(240, 20, 20, 0.8);
  border-radius: 18px;
  width: 138px;
  height: 38px;
  font-size: 14px;
  color: rgba(240, 20, 20, 0.8);
  letter-spacing: 0;
  line-height: 20px;
  display: block;
  padding: 8px 16px;
  box-sizing: border-box;
  margin-top: 12px;
  transition: 0.3s all linear;
}

.get-recom-coupon-box .getcoupon-box .introduce .coupon-del .go-my-coupon i {
  position: relative;
  top: 2px;
  font-size: 16px;
  color: rgba(240, 20, 20, 0.8);
  margin-left: 4px;
}

.get-recom-coupon-box
  .getcoupon-box
  .introduce
  .coupon-del
  .go-my-coupon:hover {
  background: #f01414;
  color: #fff;
}

.get-recom-coupon-box
  .getcoupon-box
  .introduce
  .coupon-del
  .go-my-coupon:hover
  i {
  color: #fff;
}

.get-recom-coupon-box .getcoupon-box .close-btn {
  text-align: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: inline-block;
  font-size: 16px;
  line-height: 36px;
  color: #fff;
}

.notice-modal {
  display: none;
  width: 740px;
  background-color: #fff;
  z-index: 1000;
}

.notice-modal .hd {
  margin: 0 36px;
  padding: 24px 0;
  font-size: 16px;
  border-bottom: 1px solid #d0d6d9;
}

.notice-modal .hd span {
  font-weight: 700;
}

.notice-modal .hd .close {
  position: absolute;
  right: 36px;
  top: 24px;
  line-height: 100%;
  font-size: 20px;
}

.notice-modal .bd {
  height: 570px;
  overflow-y: auto;
}

.notice-modal .notice-container {
  padding: 0 36px;
}

.notice-modal .notice-list li {
  margin-top: 10px;
  padding: 20px 0;
  border-bottom: 1px solid #d0d6d9;
}

.notice-modal .notice-list li .metas {
  padding: 10px 0;
  color: #b5b9bc;
}

.notice-modal .notice-list li h3 {
  line-height: 1;
  font-size: 16px;
  color: #1c1f21;
  font-weight: 700;
  word-break: break-all;
  word-wrap: break-word;
}

.notice-modal .notice-list li p {
  line-height: 30px;
  color: #4d555d;
  word-break: break-all;
  word-wrap: break-word;
}

.learn-container {
  width: 1152px;
  padding-top: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.learn-container .learn-container-left {
  float: left;
  position: relative;
  width: 800px;
}

.learn-container .learn-course-right {
  float: right;
  width: 304px;
  padding-top: 20px;
}

.learn-container .learn-container-nav {
  height: 48px;
  padding: 0 16px;
}

.learn-container .learn-container-nav a {
  float: left;
  margin-right: 48px;
  font-size: 14px;
  line-height: 48px;
  color: #4d555d;
  box-sizing: border-box;
}

.learn-container .learn-container-nav a:hover {
  color: #07111b;
}

.learn-container .learn-container-nav a.active {
  color: #f01414;
  border-bottom: 2px solid #f01414;
}

.learn-container .learn-container-nav a.open-certificate {
  color: #f01616;
  float: right;
  margin-right: 0;
  position: relative;
  right: -16px;
}

.learn-container .learn-container-nav a.open-certificate:hover {
  color: #c20a0a;
}

.learn-container .learn-container-nav a.open-certificate i {
  padding-left: 4px;
}

.learn-container .learning_progress {
  width: 800px;
  height: 72px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(7, 17, 27, 0.1);
  border-radius: 8px;
  padding: 18px 24px;
  box-sizing: border-box;
}

.learn-container .learning_progress .learning_progress_head {
  float: left;
  width: 36px;
  height: 36px;
  margin-right: 24px;
  border-radius: 50%;
  overflow: hidden;
}

.learn-container .learning_progress dl {
  float: left;
  margin-right: 24px;
}

.learn-container .learning_progress dt {
  font-size: 12px;
  color: #4d555d;
  line-height: 18px;
}

.learn-container .learning_progress dd {
  font-size: 16px;
  color: #f01414;
  line-height: 18px;
}

.learn-container .learning_progress dd label {
  font-size: 12px;
  display: inline-block;
  line-height: 18px;
  color: rgba(240, 20, 20, 0.4);
  margin-left: 8px;
}

.learn-container .learning_progress span {
  float: right;
  font-size: 12px;
  color: #93999f;
  line-height: 36px;
  max-width: 180px;
  overflow: hidden;
  margin-right: 12px;
  height: 36px;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.learn-container .learning_progress .continue_learn {
  float: right;
  width: 104px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: #fff;
  background: #f01414;
  border-radius: 18px;
  text-align: center;
}

.learn-container .learning_progress_fixed {
  position: fixed;
  bottom: 0;
  box-shadow: 0 0 8px 0 rgba(7, 17, 27, 0.1);
  border-radius: 4px 4px 0 0;
  visibility: hidden;
}

.learn-container .learning_zw_fixed {
  position: fixed;
  width: 800px;
  height: 72px;
  bottom: 0;
  visibility: hidden;
}

.learn-container .learn-course-list {
  padding-top: 16px;
}

body {
  background-color: #f8fafc;
}

.transition {
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.newwenda-tit {
  font-size: 16px;
}

.qa-search-list {
  display: none;
}

.qa-search-list .qa-search-tip {
  padding: 0 0 16px 16px;
}

.qa-search-list .qa-search-tip i {
  float: left;
  font-size: 24px;
  color: #f01414;
  line-height: 48px;
  margin-right: 8px;
}

.qa-search-list .qa-search-tip span {
  font-size: 14px;
  color: #07111b;
  line-height: 48px;
  font-weight: 600;
}

.qa-input-bg {
  padding: 16px 0;
}

.qa-input {
  height: 36px;
  padding: 0 128px 0 0;
  box-sizing: border-box;
  position: relative;
}

.qa-input.focus {
  box-shadow: 0 8px 16px 0 rgba(240, 20, 20, 0.1);
}

.qa-input .qa-input-text {
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  padding: 12px 16px;
  background: #fff;
  border: 1px solid #b2b8bd;
  border-radius: 8px;
}

.qa-input .search-qa-btn {
  font-size: 24px;
  color: #9199a1;
  line-height: 24px;
  position: absolute;
  top: 12px;
  right: 140px;
  cursor: pointer;
}

.qa-input .search-qa-btn:hover {
  color: rgba(245, 61, 61, 0.1);
}

.qa-input :-moz-placeholder {
  color: #93999f;
}

.qa-input ::-moz-placeholder {
  color: #93999f;
}

.qa-input input:-ms-input-placeholder {
  color: #93999f;
}

.qa-input input::-webkit-input-placeholder {
  color: #93999f;
}

.qa-input .js-submit-box,
.qa-input .qa-input-button {
  position: absolute;
  width: 104px;
  height: 36px;
  right: 0;
  top: 0;
  border-radius: 24px;
  font-size: 16px;
}

.qa-input .qa-input-close {
  display: none;
  position: absolute;
  top: 12px;
  right: 170px;
  cursor: pointer;
  font-size: 16px;
  color: #d3d6d9;
  line-height: 24px;
}

.qa-input .qa-input-close:hover {
  color: #93999f;
}

.qa-input-outer {
  width: 100%;
  height: 36px;
  padding: 0 88px 0 0;
  box-sizing: border-box;
  position: relative;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.qa-input-outer.hideAddQaBtn {
  padding: 0;
}

.qa-input-outer .qa-input-inner {
  width: 100%;
  padding: 0 56px 0 0;
  position: relative;
  box-sizing: border-box;
}

.qa-input-outer .qa-input-inner .search-qa-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 56px;
  height: 36px;
  background: #f53d3d;
  border-radius: 0 8px 8px 0;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.qa-input-outer .qa-input-inner .search-qa-btn:hover {
  background: #f53d3d;
}

.qa-input-outer .qa-input-inner .search-qa-btn i {
  font-size: 20px;
  color: #fff;
  line-height: 36px;
}

.qa-input-outer .qa-input-inner input {
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  padding: 0 16px;
  background: #fff;
  border: 1px solid #b2b8bd;
  border-radius: 8px;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 14px;
  line-height: 36px;
}

.qa-input-outer .qa-input-inner input::-moz-placeholder {
  color: #93999f;
}

.qa-input-outer .qa-input-inner input::-moz-placeholder {
  color: #93999f;
}

.qa-input-outer .qa-input-inner input::-ms-input-placeholder {
  color: #93999f;
}

.qa-input-outer .qa-input-inner input::-webkit-input-placeholder {
  color: #93999f;
}

.qa-input-outer .qa-input-inner .qa-input-close {
  display: none;
  position: absolute;
  top: 0;
  right: 70px;
  cursor: pointer;
  font-size: 16px;
  color: #d3d6d9;
  line-height: 36px;
}

.qa-input-outer .qa-input-inner .qa-input-close:hover {
  color: #717a84;
}

.qa-input-outer .qa-add-btn {
  position: absolute;
  display: inline-block;
  width: 76px;
  height: 36px;
  right: 0;
  top: 0;
  font-size: 16px;
  color: #fff;
  line-height: 36px;
  background: #f53d3d;
  border-radius: 24px;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.qa-input-outer .qa-add-btn:hover {
  background: #f53d3d;
}

.qa-input-outer .qa-add-btn.hideAddQaBtn {
  opacity: 0;
  transform: scale(0);
  -webkit-transform: scale(0);
}

.qa-input-outer .search-relative {
  display: none;
  z-index: 1;
  position: absolute;
  top: 52px;
  left: 0;
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(28, 31, 33, 0.2);
  border-radius: 6px;
}

.qa-input-outer .search-relative h2 {
  font-size: 14px;
  color: #1c1f21;
  text-align: left;
  line-height: 22px;
}

.qa-input-outer .search-relative ul li {
  margin-top: 6px;
  display: flex;
  align-items: center;
}

.qa-input-outer .search-relative ul li .tag {
  width: 48px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: rgba(31, 173, 78, 0.1);
  margin-right: 6px;
  font-size: 12px;
  color: #1fad4e;
}

.qa-input-outer .search-relative ul li .name {
  display: block;
  width: 100%;
  padding: 0 6px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.qa-input-outer .search-relative ul li .name:hover {
  background: rgba(56, 61, 66, 0.06);
}

.qa-input-outer .search-relative ul li .name p {
  font-size: 14px;
  color: #545c63;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.qa-input-outer .search-relative ul li .name p .highlight {
  color: #f53d3d;
  font-style: normal;
  font-weight: 700;
}

.search-wrap {
  margin: 0 24px;
}

.no-result {
  display: none;
  margin: 0 24px;
  padding: 96px 0;
  height: 312px;
  background: #fff;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  color: #93999f;
  line-height: 24px;
  box-sizing: border-box;
}

.qa-search-row {
  display: none;
  width: auto;
  height: 40px;
  padding: 0 16px;
  margin-bottom: 18px;
  line-height: 40px;
  box-sizing: border-box;
  position: relative;
  background: #f8fafc;
  border-radius: 4px;
}

.qa-search-row p {
  font-size: 14px;
  color: #1c1f21;
  line-height: 40px;
  text-align: left;
}

.qa-search-row p em {
  margin: 0 4px;
  font-style: normal;
  color: #f53d3d;
}

.qa-search-row .out-search {
  font-size: 14px;
  color: #545c63;
  line-height: 40px;
  position: absolute;
  top: 0;
  right: 16px;
  cursor: pointer;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.qa-search {
  padding-right: 88px;
  position: relative;
}

.qa-search .qa-input {
  width: 100%;
  height: 36px;
  padding: 0 12px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 18px;
  background: #fff;
  border: 1px solid #b2b8bd;
  border-radius: 6px;
}

.qa-search .qa-input .qa-input-text {
  width: 100%;
  height: 34px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 34px;
}

.qa-search .qa-input .sz-search {
  position: absolute;
  font-size: 24px;
  color: #9199a1;
  line-height: 24px;
  top: 6px;
  right: 16px;
  cursor: pointer;
}

.qa-search .qa-input .qa-input-close {
  display: none;
  position: absolute;
  top: 6px;
  right: 52px;
  cursor: pointer;
  font-size: 24px;
  color: #d3d6d9;
  line-height: 24px;
}

.qa-search .qa-input-button,
.qa-search .qa-input-link {
  position: absolute;
  width: 76px;
  height: 36px;
  right: 0;
  top: 0;
  background: #f53d3d;
  border-radius: 18px;
  font-size: 14px;
  color: #fff;
  line-height: 36px;
  text-align: center;
}

.qa-search .search-history-area {
  padding: 8px 0 12px;
  width: 640px;
  position: absolute;
  top: 54px;
  background: #fff;
  z-index: 100;
  box-sizing: border-box;
  box-shadow: 0 8px 16px 0 rgba(28, 31, 33, 0.2);
  border-radius: 8px;
  display: none;
}

.qa-search .search-history-area h3 {
  padding: 0 16px;
  font-size: 14px;
  color: #1c1f21;
  line-height: 36px;
  font-weight: 700;
}

.qa-search .search-history-area li {
  padding-left: 16px;
  line-height: 44px;
  font-size: 14px;
}

.qa-search .search-history-area li a {
  color: #545c63;
}

.qa-search .search-history-area li:hover {
  background: rgba(28, 31, 33, 0.06);
}

.qa-search .search-history-area li:hover a {
  color: #1c1f21;
}

.qa-search .search-history-area li span {
  margin-right: 5px;
  padding: 4px;
  background: rgba(56, 61, 66, 0.06);
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
}

.qa-search .search-history-area li span.isfinish {
  background: rgba(31, 173, 78, 0.1);
  color: #1fad4e;
}

.newtagblock {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.newtagblock .label {
  display: inline-block;
  height: 32px;
  padding: 0 16px;
  box-sizing: border-box;
  text-align: center;
  background: #f3f5f6;
  border-radius: 2px;
  line-height: 32px;
  font-size: 12px;
  color: #545c63;
  margin: 0 10px 10px 0;
  cursor: pointer;
}

.newtagblock .label:hover {
  background: rgba(242, 13, 13, 0.05);
  color: #f53d3d;
}

.qa-nav {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(28, 31, 33, 0.1);
  overflow: hidden;
}

.qa-nav .myques-btn {
  font-weight: 400;
  text-align: right;
  color: #9199a1;
  font-size: 12px;
  float: right;
  position: relative;
  top: 18px;
}

.qa-nav .myques-btn:hover {
  color: #f20d0d;
}

.qa-nav .ques-tab {
  float: left;
  font-size: 16px;
  color: #545c63;
  line-height: 24px;
  position: relative;
  text-align: center;
  margin-right: 32px;
  margin-top: 6px;
}

.qa-nav .ques-tab.active,
.qa-nav .ques-tab:hover {
  font-size: 16px;
  color: #f20d0d;
  line-height: 24px;
}

.qa-nav .ques-tab.active .line,
.qa-nav .ques-tab:hover .line {
  display: inline-block;
  width: 25px;
  border-top: 3px solid #f20d0d;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.qa-list {
  padding-bottom: 36px;
}

.qa-list .qa-serach-tip {
  font-size: 14px;
  color: #07111b;
  line-height: 48px;
}

.qa-list .qa-serach-tip i,
.qa-list .qa-serach-tip span {
  float: left;
}

.qa-list .qa-serach-tip span {
  font-weight: 700;
}

.qa-list .qa-serach-tip i {
  font-size: 24px;
  color: #f01414;
  line-height: 48px;
  margin-right: 8px;
}

.qa-no-data {
  padding: 112px 0;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
}

.qa-no-data img {
  display: block;
  width: 238px;
  height: auto;
  margin: 0 auto;
}

.qa-no-data p {
  font-size: 14px;
  color: #383d42;
  text-align: center;
  word-wrap: break-word;
  word-break: normal;
}

.qa-no-data .wenbtn {
  margin: 16px auto 0;
  display: block;
  width: 76px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  color: #fff;
  background: #f20d0d;
  border-radius: 18px;
  cursor: pointer;
}

.qa-list-item {
  padding: 16px 0;
  position: relative;
  border-bottom: 1px solid #1a1c1f21;
}

.qa-list-item:last-child {
  border-bottom: 0;
}

.qa-list-item.uheadshow {
  margin-left: 64px;
}

.qa-list-item .nwenda-box {
  display: block;
  position: relative;
  padding-left: 48px;
  box-sizing: border-box;
}

.qa-list-item .wendahead {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: 24px;
  background: #ccc;
}

.qa-list-item .nwenda-con {
  width: 100%;
}

.qa-list-item .nwenda-con h3 {
  max-height: 48px;
  line-height: 24px;
  font-size: 16px;
  color: #1c1f21;
  font-weight: 600;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.qa-list-item .nwenda-con h3 .highlight {
  color: #f20d0d;
}

.qa-list-item .nwenda-con h3.gold {
  color: #c80;
}

.qa-list-item .nwenda-con h3 span {
  height: 20px;
  padding: 0 6px;
  box-sizing: border-box;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
}

.qa-list-item .nwenda-con h3 span.top {
  background: rgba(255, 170, 0, 0.15);
  color: #c80;
}

.qa-list-item .nwenda-con h3 span.adopt {
  background: rgba(31, 171, 78, 0.1);
  color: #1fad4e;
}

.qa-list-item .nwenda-con h3 span.share {
  background: rgba(25, 195, 230, 0.18);
  color: #149cb8;
}

.qa-list-item .nwenda-con h3 span.new {
  background: rgba(242, 13, 13, 0.05);
  color: #f53d3d;
}

.qa-list-item .nwenda-con .desc {
  margin-top: 6px;
  line-height: 22px;
  max-height: 44px;
  font-size: 14px;
  color: #383d42;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.qa-list-item .nwenda-con .desc .answername {
  font-weight: 600;
}

.qa-list-item .footer-info {
  margin-top: 10px;
  padding-left: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #9199a1;
}

.qa-list-item .footer-info .info-l {
  display: flex;
  flex-direction: row;
}

.qa-list-item .footer-info .provenum,
.qa-list-item .footer-info .viewnum {
  margin-right: 24px;
}

.qa-list-item .footer-info .replynum {
  margin-right: 4px;
}

.qa-list-item .footer-info .replynum i,
.qa-list-item .footer-info .viewnum i {
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -2px;
}

.qa-list-item .footer-info .provenum i {
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -4px;
}

.qa-list-item .footer-info .headimg {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: 16px;
}

.qa-list-item .footer-info .headimg img {
  height: 16px;
  width: 16px;
  border-radius: 24px;
  margin-left: -8px;
}

.qa-list-item .qa-u-head {
  position: absolute;
  left: -64px;
  top: 28px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.qa-list-item .qa-u-head img {
  width: 100%;
  border-radius: 50%;
}

.qa-list-item .qa-u-head .qa-item-check {
  display: none;
  position: absolute;
  width: 16px;
  height: 16px;
  background: #00b43c;
  border: 1px solid #fff;
  left: 32px;
  top: 32px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
}

.qa-list-item .qa-u-head i {
  color: #fff;
}

.qa-list-item .qa-item-container {
  width: 100%;
}

.qa-list-item .qa-reply-a {
  padding: 8px 16px;
  background: rgba(28, 31, 33, 0.08);
  border-radius: 16px;
  float: left;
  height: 32px;
  box-sizing: border-box;
  color: #545c63;
}

.qa-list-item .qa-reply-a i,
.qa-list-item .qa-reply-a span {
  float: left;
  line-height: 14px;
  color: #545c63;
}

.qa-list-item .qa-reply-a span {
  font-size: 12px;
}

.qa-list-item .qa-reply-a i {
  margin-left: -4px;
  margin-right: 2px;
  font-size: 16px;
  transform: rotate(45deg);
}

.qa-list-item .qa-reward {
  position: relative;
  float: left;
  background: #f90;
  box-shadow: 0 2px 4px 0 rgba(7, 17, 27, 0.2);
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
  line-height: 28px;
  width: 112px;
  height: 28px;
  padding: 0 24px 0 12px;
  margin: 2px 0 0 8px;
  opacity: 0;
}

.qa-list-item .qa-reward .qa-trangle {
  position: absolute;
  left: -4px;
  top: 10px;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-right: 4px solid #f90;
  border-bottom: 4px solid transparent;
}

@-webkit-keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.qa-list-item .qa-reward.slow-show {
  -webkit-animation-name: show;
  animation-name: show;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.qa-list-item .qa-reward .qa-reward-close {
  position: absolute;
  top: 0;
  right: 4px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
  line-height: 28px;
  cursor: pointer;
  transition: 0.1s all linear;
}

.qa-list-item .qa-reward .qa-reward-close:hover {
  color: #fff;
}

.qa-list-item .qa-answer {
  width: 100%;
  box-sizing: border-box;
}

.qa-list-item .qa-answer.border {
  border-left: 2px solid #d9dde1;
  padding-left: 12px;
}

.qa-list-item .qa-answer .qa-answer-t {
  margin-bottom: 2px;
}

.qa-list-item .qa-answer span {
  float: left;
  font-size: 12px;
  color: #545c63;
  line-height: 24px;
}

.qa-list-item .qa-answer .qa-answer-adopt {
  color: #1fad4e;
}

.qa-list-item .qa-answer .qa-answer-line {
  margin: 0 8px;
}

.qa-list-item .qa-answer a:hover span {
  color: #1c1f21;
}

.qa-list-item .qa-answer i {
  float: left;
  width: 14px;
  height: 14px;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.qa-list-item .qa-answer p {
  font-size: 12px;
  color: #4d555d;
  line-height: 24px;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.qa-list-item .qa-item-bottom {
  padding-top: 16px;
}

.qa-list-item .qa-item-bottom a,
.qa-list-item .qa-item-bottom span {
  float: left;
  font-size: 12px;
  color: #93999f;
  line-height: 24px;
  margin-right: 24px;
}

.qa-list-item .qa-item-bottom a:hover {
  color: #07111b;
}

.qa-list-item .qa-item-bottom .qa-item-time {
  float: right;
  margin-right: 0;
}

.qa-list-item .highlight {
  color: #f01414;
  font-style: normal;
}

.put-qa-opa {
  z-index: 998;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.put-qa-container .put-qa-head {
  height: 24px;
  padding: 24px 0;
}

.put-qa-container .put-qa-head span {
  float: left;
  font-size: 16px;
  color: #07111b;
  line-height: 24px;
}

.put-qa-container .put-qa-head i {
  float: right;
  font-size: 24px;
  color: #93999f;
  line-height: 24px;
  cursor: pointer;
}

.put-qa-container .put-qa-head i:hover {
  color: #07111b;
}

.put-qa-container .put-qa-input {
  display: block;
  width: 100%;
  background: #fff;
  border: 1px solid #d9dde1;
  border-radius: 2px;
  font-size: 16px;
  height: 48px;
  color: #93999f;
  line-height: 24px;
  padding: 12px;
  box-sizing: border-box;
}

.put-qa-container .put-qa-error {
  height: 24px;
  font-size: 12px;
  color: #f01414;
  line-height: 24px;
  padding: 0 5px;
}

.put-qa-container .put-qa-detail {
  width: 100%;
}

.put-qa-container .put-qa-m {
  float: left;
  width: 320px;
  height: 36px;
  margin-right: 20px;
  background: #fff;
  border: 1px solid #d9dde1;
  border-radius: 2px;
  position: relative;
}

.put-qa-container .put-qa-m span {
  font-size: 14px;
  color: #93999f;
  line-height: 24px;
}

.put-qa-container .put-qa-mbtn {
  padding: 6px 8px 6px 12px;
  width: 320px;
  height: 36px;
  cursor: pointer;
  box-sizing: border-box;
}

.put-qa-container .put-qa-tips {
  float: left;
  font-size: 16px;
  color: #f90;
  line-height: 36px;
  margin-right: 4px;
}

.put-qa-container .put-qa-span {
  font-size: 12px;
  color: #f90;
  line-height: 36px;
}

.put-qa-container .put-qa-mdown {
  float: right;
  font-size: 16px;
  color: #93999f;
  line-height: 24px;
}

.put-qa-container .put-qa-submit {
  float: right;
  background: #f01414;
  border-radius: 2px;
  width: 76px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #fff;
}

.put-qa-container .put-qa-mlist {
  display: none;
  position: absolute;
  bottom: 38px;
  left: -1px;
  max-height: 280px;
  width: 320px;
  background: #fff;
  box-shadow: 0 -4px 8px rgba(7, 17, 27, 0.2);
  -moz-box-shadow: 0 -4px 8px rgba(7, 17, 27, 0.2);
  -webkit-box-shadow: 0 -4px 8px rgba(7, 17, 27, 0.2);
  border-radius: 4px 4px 0 0;
  z-index: 999;
  cursor: pointer;
  overflow: auto;
}

.put-qa-container .put-qa-mlist span {
  width: 100%;
  display: block;
  padding: 0 10px;
  font-size: 14px;
  color: #93999f;
  width: inherit;
  text-align: left;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.put-qa-container .put-qa-mlist span.curr,
.put-qa-container .put-qa-mlist span:hover {
  color: #07111b;
  background: #f3f5f7;
}

.put-qa-container .put-qa-bottom {
  position: relative;
}

.put-qa-container .put-qa-bottom .captcha_con .captcha {
  width: 198px;
  height: 36px;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #9199a1;
  border-radius: 6px;
  position: relative;
}

.put-qa-container .put-qa-bottom .captcha_con .captcha .captcha-input {
  width: 112px;
  height: 34px;
  border-radius: 6px;
  position: absolute;
  box-sizing: border-box;
  padding: 8px 16px 8px 12px;
  font-size: 14px;
  color: #9199a1;
  line-height: 20px;
}

.put-qa-container .put-qa-bottom .captcha_con .captcha i {
  position: absolute;
  top: 5px;
  left: 112px;
  font-size: 16px;
  color: #9199a1;
  line-height: 24px;
  cursor: pointer;
}

.put-qa-container .put-qa-bottom .captcha_con .captcha img {
  width: 60px;
  height: 32px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.put-qa-container .put-qa-bottom .captcha_con .errText {
  display: none;
  margin-top: 4px;
  font-size: 12px;
  color: #f20d0d;
  line-height: 18px;
}

.put-qa-container .put-qa-bottom .captcha-verify-box {
  position: absolute;
  right: -128px;
  bottom: 55px;
  padding: 6px;
  width: 320px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d0d6d9;
  z-index: 1000;
}

.put-qa-container .put-qa-bottom .captcha-verify-box:after {
  position: absolute;
  bottom: -11px;
  left: 156px;
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 10px solid #d0d6d9;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  z-index: 9;
}

.put-qa-container .put-qa-bottom .captcha-verify-box:before {
  position: absolute;
  bottom: -9px;
  left: 157px;
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 9px solid #fff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  z-index: 10;
}

.put-qa-container .put-qa-bottom .captcha-verify-box .js-mocaptcha-close {
  position: absolute;
  right: -40px;
  top: -7px;
  display: block;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

body {
  background-color: #f3f5f7;
}

.transition {
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.minheight {
  min-height: 750px;
  padding-bottom: 60px;
}

.put-qa-opa {
  z-index: 98;
}

.put-qa-container {
  z-index: 99;
}

.qa-container {
  width: 1152px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 36px;
}

.qa-container .qa-container-left {
  float: left;
  width: 832px;
}

.qa-container .qa-container-right {
  float: right;
}

.searchblock {
  padding-bottom: 16px;
  border-bottom: 1px solid #d3d6d9;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 9;
}

.searchblock span {
  font-size: 14px;
  color: #545c63;
  line-height: 22px;
}

.searchblock span em {
  font-style: normal;
  color: #f53d3d;
  margin: 0 4px;
}

.searchblock .search-outer-bg {
  width: 294px;
  position: absolute;
  right: 0;
  top: 0;
}

.searchblock .search-outer {
  width: 100%;
  height: 32px;
  padding: 0 84px 0 0;
  box-sizing: border-box;
  position: relative;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.searchblock .search-outer.hideAddQaBtn {
  padding: 0;
}

.searchblock .search-inner {
  width: 100%;
  height: 32px;
  position: relative;
  padding: 0 40px 0 0;
  box-sizing: border-box;
}

.searchblock .search-inner input[type="text"] {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  box-sizing: border-box;
  background: rgba(242, 13, 13, 0.05);
  border-radius: 4px 0 0 4px;
  font-size: 12px;
}

.searchblock .search-inner .search-input-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 0 4px 4px 0;
  background: #f53d3d;
  cursor: pointer;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.searchblock .search-inner .search-input-btn i.imv2-search {
  font-size: 16px;
  color: #fff;
  line-height: 32px;
}

.searchblock a.search-add-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 74px;
  height: 32px;
  text-align: center;
  background: #f53d3d;
  line-height: 32px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

.searchblock a.search-add-btn i.imv2-yuanwen {
  line-height: 32px;
  color: #fff;
  font-size: 16px;
  vertical-align: middle;
  display: inline-block;
  margin-top: -1px;
  margin-right: 3px;
}

.searchblock a.search-add-btn.hideAddQaBtn {
  opacity: 0;
  transform: scale(0);
  -webkit-transform: scale(0);
}

.searchblock .search-relative {
  display: none;
  position: absolute;
  top: 36px;
  left: 0;
  width: 294px;
  padding: 8px 12px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(28, 31, 33, 0.2);
  border-radius: 6px;
}

.searchblock .search-relative h2 {
  font-size: 14px;
  color: #1c1f21;
  text-align: left;
  line-height: 22px;
}

.searchblock .search-relative ul li {
  margin-top: 6px;
  display: flex;
  align-items: center;
}

.searchblock .search-relative ul li .tag {
  width: 48px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: rgba(31, 173, 78, 0.1);
  margin-right: 6px;
  font-size: 12px;
  color: #1fad4e;
}

.searchblock .search-relative ul li .name {
  display: block;
  width: 216px;
  min-height: 32px;
  padding: 0 6px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchblock .search-relative ul li .name:hover {
  background: rgba(56, 61, 66, 0.06);
}

.searchblock .search-relative ul li .name p {
  font-size: 14px;
  color: #545c63;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.searchblock .search-relative ul li .name p .highlight {
  color: #f20d0d;
  font-style: normal;
  font-weight: 700;
}

.searchresult {
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin: 16px 0 24px 0;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  position: relative;
}

.searchresult p {
  padding-left: 16px;
  box-sizing: border-box;
  color: #1c1f21;
  font-size: 14px;
}

.searchresult p em {
  font-style: normal;
  color: #f53d3d;
}

.searchresult .out-search {
  position: absolute;
  top: 0;
  right: 20px;
  color: #545c63;
  font-size: 14px;
  cursor: pointer;
}

.label-box {
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  height: 90px;
  overflow: hidden;
  transition: all 0.2s;
}

.label-box .label-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.label-box .label-list .label-item {
  flex: 0 0 calc(25% - 17px);
  height: 74px;
  box-sizing: border-box;
  box-shadow: rgba(7, 17, 27, 0.07) 0 4px 8px 0;
  border-radius: 8px;
  margin: 0 22px 16px 0;
  padding: 14px 18px;
  background: #fff no-repeat bottom right/82px;
}

.label-box .label-list .label-item .name {
  max-width: 7em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  color: #383d42;
  line-height: 24px;
  margin-bottom: 4px;
  transition: all 0.2s;
}

.label-box .label-list .label-item .entrybtn {
  font-size: 12px;
  color: #9199a1;
  line-height: 18px;
  transition: all 0.2s;
}

.label-box .label-list .label-item:nth-child(4n) {
  margin-right: 0;
}

.label-box .label-list .label-item.active .name,
.label-box .label-list .label-item:hover .name {
  color: #f20d0d;
}

.label-box .label-list .label-item.active .entrybtn,
.label-box .label-list .label-item:hover .entrybtn {
  color: #ef6060;
}

.label-box .unfold {
  color: #9199a1;
  margin-left: 18px;
  background: #edf1f5;
  border-radius: 4px;
  cursor: pointer;
}

.label-box .unfold span {
  display: block;
  transition: all 0.2s;
  font-size: 24px;
  line-height: 74px;
}

.label-box .unfold.active span {
  transform: rotate(180deg);
}

.newqueslable {
  width: 100%;
}

.newqueslable .callback {
  display: block;
  width: 70px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  background: rgba(245, 61, 61, 0.1);
  border-radius: 16px;
  color: #f20d0d;
  font-size: 12px;
}

.newqueslable .ofterwenda {
  margin-top: 16px;
  background: url(/static/module/img/newques/newqueslabelbg.png) no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 92px;
}

.newqueslable .ofterwenda h3 {
  text-align: center;
  font-size: 16px;
  color: #1c1f21;
  line-height: 22px;
  font-weight: 600;
  padding-top: 20px;
  box-sizing: border-box;
}

.newqueslable .ofterwenda p {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  color: #545c63;
}

.newqueslable .ofterwenda p span {
  margin-right: 32px;
}

.newqueslable .ofterwenda p span em {
  color: #f53d3d;
  margin-right: 2px;
}

.wenda-tab {
  margin: 16px auto 8px 0;
  position: relative;
}

.wenda-tab a {
  display: inline-block;
  margin-right: 24px;
  line-height: 30px;
  font-size: 14px;
  color: #545c63;
  cursor: pointer;
}

.wenda-tab a em {
  color: #9199a1;
  font-style: normal;
  font-weight: 400;
}

.wenda-tab a.onactive {
  color: #f20d0d;
}

.newquse-item {
  padding: 20px 32px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(7, 17, 27, 0.05);
  border-radius: 12px;
  margin-top: 10px;
  position: relative;
}

.newquse-item .closeicon {
  position: absolute;
  right: 16px;
  top: 12px;
  font-size: 16px;
  color: #9199a1;
  cursor: pointer;
}

.newquse-item:hover {
  box-shadow: 0 4px 8px 0 rgba(28, 31, 33, 0.3);
}

.newquse-item:hover .footer-info .charpterbtn {
  display: block;
}

.newquse-item .nwenda-box {
  display: block;
  position: relative;
  padding-left: 48px;
  box-sizing: border-box;
}

.newquse-item .nwenda-box:active,
.newquse-item .nwenda-box:hover {
  color: #383d42;
}

.newquse-item .nwenda-box .wendahead {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: 24px;
  background: #ccc;
}

.newquse-item .nwenda-box .nwenda-con {
  width: 100%;
}

.newquse-item .nwenda-box .nwenda-con h3 {
  max-height: 48px;
  line-height: 24px;
  font-size: 16px;
  color: #1c1f21;
  font-weight: 600;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.newquse-item .nwenda-box .nwenda-con h3 .highlight {
  color: #f20d0d;
  font-style: normal;
  font-weight: 700;
}

.newquse-item .nwenda-box .nwenda-con h3.gold {
  color: #c80;
}

.newquse-item .nwenda-box .nwenda-con .adopt,
.newquse-item .nwenda-box .nwenda-con .new,
.newquse-item .nwenda-box .nwenda-con .share,
.newquse-item .nwenda-box .nwenda-con .top {
  height: 20px;
  padding: 0 6px;
  box-sizing: border-box;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
}

.newquse-item .nwenda-box .nwenda-con .top {
  background: rgba(255, 170, 0, 0.15);
  color: #c80;
}

.newquse-item .nwenda-box .nwenda-con .share {
  background: rgba(25, 195, 230, 0.18);
  color: #149cb8;
}

.newquse-item .nwenda-box .nwenda-con .adopt {
  background: rgba(31, 171, 78, 0.1);
  color: #1fad4e;
}

.newquse-item .nwenda-box .nwenda-con .new {
  background: rgba(242, 13, 13, 0.05);
  color: #f53d3d;
}

.newquse-item .nwenda-box .nwenda-con .desc {
  margin-top: 6px;
  line-height: 22px;
  max-height: 44px;
  font-size: 14px;
  color: #383d42;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.newquse-item .footer-info {
  margin-top: 10px;
  padding-left: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #9199a1;
}

.newquse-item .footer-info .info-l {
  display: flex;
  flex-direction: row;
}

.newquse-item .footer-info .provenum,
.newquse-item .footer-info .viewnum {
  margin-right: 24px;
}

.newquse-item .footer-info .replynum {
  margin-right: 4px;
}

.newquse-item .footer-info .replynum i,
.newquse-item .footer-info .viewnum i {
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -2px;
}

.newquse-item .footer-info .provenum i {
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -4px;
}

.newquse-item .footer-info .headimg {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: 16px;
}

.newquse-item .footer-info .headimg img {
  height: 16px;
  width: 16px;
  border-radius: 24px;
  margin-left: -8px;
}

.newquse-item .footer-info .charpterbtn {
  display: none;
  margin-left: 24px;
}

.newquse-item .footer-info .charpterbtn:hover {
  color: #9199a1;
}

.newqueslistblock .loading {
  padding: 100px 0 100px;
  background: #fff;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(7, 17, 27, 0.05);
  border-radius: 12px;
}

.newqueslistblock .loading img {
  width: 36px;
  height: 36px;
}

.dataempty {
  padding: 165px;
  width: 100%;
  height: 650px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(7, 17, 27, 0.05);
  border-radius: 12px;
}

.dataempty img {
  display: block;
  width: 297px;
  height: auto;
  margin: 0 auto;
}

.dataempty p {
  font-size: 14px;
  color: #383d42;
  text-align: center;
}

.dataempty .wenbtn {
  margin: 16px auto 0;
  display: block;
  width: 76px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  color: #fff;
  background: #f20d0d;
  border-radius: 18px;
}

.closelyQues-ignore-choosebox {
  background-color: #fff;
  overflow: hidden;
  border-radius: 12px;
}

.closelyQues-ignore-choosebox .selectBox {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  margin-top: 29px;
  margin-bottom: 13px;
  background-image: url(/static/module/common/img/prompt.png);
  background-size: cover;
}

.closelyQues-ignore-choosebox .otherBox {
  text-align: center;
}

.closelyQues-ignore-choosebox .otherBox p {
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #1c1f21;
  line-height: 24px;
  font-weight: 700;
  margin: 0 auto;
  text-align: center;
}

.closelyQues-ignore-choosebox .btnBox {
  overflow: hidden;
  margin-top: 26px;
}

.closelyQues-ignore-choosebox .btnBox div {
  background: #383d42;
  border-radius: 18px;
  height: 36px;
  line-height: 36px;
  padding: 0 24px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.closelyQues-ignore-choosebox .btnBox .cancelBtn {
  background: rgba(84, 92, 99, 0.1);
  color: #545c63;
  margin-right: 8px;
}

.closelyQues-ignore-choosebox .btnBox .sureBtn {
  margin-right: 78px;
}

.moco-modal-dialog {
  padding: 0 !important;
  height: 240px !important;
}

.moco-modal-layer {
}

.js-search-page {
  overflow: hidden;
  padding: 36px 0;
  box-sizing: border-box;
  text-align: center;
  display: none;
}

.search-page {
  width: 100%;
  text-align: center;
  user-select: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-page a {
  display: inline-block;
  margin: 0 12px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: #4d555d;
  text-align: center;
  border-radius: 18px;
  cursor: pointer;
}

.search-page a:first-child,
.search-page a:last-child,
.search-page a:nth-child(2),
.search-page a:nth-last-child(2) {
  width: auto;
}

.search-page a:first-child:hover,
.search-page a:last-child:hover,
.search-page a:nth-child(2):hover,
.search-page a:nth-last-child(2):hover {
  background-color: transparent;
}

.search-page a:hover {
  text-decoration: none;
  background-color: #d9dde1;
}

.search-page a.active {
  background: #4d555d;
  color: #fff;
}

.search-page a.disabled {
  color: #93999f;
  cursor: no-drop;
}

.wrap-r {
  width: 288px;
}

.borderstyle {
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 16px;
}

.myblock {
  padding: 15px 20px;
  box-sizing: border-box;
  font-size: 14px;
}

.myblock a.item {
  display: block;
  line-height: 24px;
  padding: 7px 20px;
  color: #545c63;
  overflow: hidden;
  border-radius: 8px;
}

.myblock a.item i {
  margin-right: 10px;
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
  margin-top: -2px;
}

.myblock a.item .num {
  font-style: normal;
  font-size: 14px;
  display: inline-block;
  float: right;
}

.myblock a.item:hover {
  color: #545c63;
}

.myblock a.item:hover .num {
  color: #f53d3d;
}

.myblock a.item.active {
  background: rgba(242, 13, 13, 0.05);
  border-radius: 8px;
  color: #545c63;
}

.myblock a.item.active .num {
  color: #f53d3d;
}

.teacherblock {
  padding: 25px 30px;
  box-sizing: border-box;
}

.teacherblock .teacherhead {
  border-bottom: 1px solid rgba(28, 31, 33, 0.2);
  padding-bottom: 16px;
}

.teacherblock .teacherhead .head {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #ccc;
  float: right;
}

.teacherblock .teacherhead .tinfo {
  margin-right: 48px;
}

.teacherblock .teacherhead .tinfo .nickname {
  font-size: 16px;
  color: #1c1f21;
  font-weight: 700;
  margin-bottom: 4px;
  max-width: 115px;
  height: 24px;
  display: flex;
  align-items: center;
}

.teacherblock .teacherhead .tinfo .nickname span {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.teacherblock .teacherhead .tinfo .nickname i {
  font-size: 20px;
  color: #f01400;
  vertical-align: middle;
  display: inline-block;
  margin-top: -5px;
}

.teacherblock .teacherhead .tinfo .job {
  font-size: 12px;
  color: #717a84;
}

.teacherblock .teacherinfo {
  padding-top: 16px;
  font-size: 14px;
  color: #545c63;
  line-height: 22px;
}

.teacherblock .teacherinfo h3 {
  font-weight: 700;
  font-size: 14px;
  color: #1c1f21;
}

.teacherblock .teacherinfo .taboutme {
  word-wrap: break-word;
  word-break: break-all;
}

.teacherblock .teacherinfo .tewlist {
  margin-top: 6px;
}

.teacherblock .teacherinfo .tewlist .item {
  cursor: pointer;
  display: block;
  padding: 12px 0;
  border-bottom: 1px dashed #d3d6d9;
}

.teacherblock .teacherinfo .tewlist .item:first-child {
  padding-top: 0;
}

.teacherblock .teacherinfo .tewlist .item:last-child {
  border-bottom: 0;
}

.teacherblock .teacherinfo .tewlist .item .title {
  font-size: 14px;
  color: #545c63;
  line-height: 22px;
  max-height: 44px;
  font-weight: 400;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  word-break: break-all;
}

.teacherblock .teacherinfo .tewlist .item .footinfo {
  font-size: 12px;
  color: #9199a1;
  height: 16px;
  line-height: 16px;
  margin-top: 4px;
}

.teacherblock .teacherinfo .tewlist .item .footinfo span {
  margin-right: 16px;
}

.teacherblock .teacherinfo .tewlist .item .footinfo i {
  vertical-align: middle;
  font-size: 16px;
  display: inline-block;
  margin-top: -3px;
  margin-right: 4px;
}

.teacherblock .moretearch {
  display: block;
  margin: 24px auto 0;
  width: 116px;
  height: 36px;
  font-size: 14px;
  color: #f20d0d;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  border: 1px solid rgba(242, 13, 13, 0.3);
  border-radius: 18px;
}
</style>
