<template>
  <div>
    <div id="sz-banner" class="shizhan-banner">
      <div class="wrap clearfix">
        <div class="sz-banner-left">
          <div class="crumbs clearfix">
            <a href="#/">实战</a>
            <i>\</i>
            <span>SpringCloud Finchley三版本微服务实战</span>
          </div>
          <h2 class="course-title">
            <a href="#/class/187.html">SpringCloud Finchley三版本微服务实战</a>
          </h2>

          <div class="shizhan-nav clearfix">
            <a
              @click="activeName = '课程'"
              :class="{ on: activeName == '课程' }"
              class="clearfix"
            >
              <i class="sz-course"></i>
              <span>课程</span>
            </a>
            <a
              @click="activeName = '问答'"
              :class="{ on: activeName == '问答' }"
            >
              <i class="sz-evaluation"></i>
              <span>问答</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <canner1 :dataList="dataList" v-show="activeName == '课程'" />
    <canner2 v-show="activeName == '问答'" />
  </div>
</template>
<script>
import canner1 from "../../components/learlist/canner1.vue";
import canner2 from "../../components/learlist/canner2.vue";
export default {
  components: {
    canner1,
    canner2,
  },
  data() {
    return {
      activeName: "课程",
      data: {
        id: 1,
        name: "Spring 5实战开发及新特性精讲3",
        type: 2,
        price: 288.0,
        level: 2,
        imagesmall:
          "http://121.201.66.25/group1/M00/00/00/eclCGWItmamAC0VLAAAgGvRZcSI844.jpg",
        ishot: 1,
        categoryid: 4,
        recommend: 8,
        isgroup: 8,
        slogan: "视角更全面，梳理更透彻，再也不担心 Spring 项目的改造升级",
        viewpoint:
          "随着 Spring的迭代升级，基于Spring 5的项目开发和改造需求爆发式增长。本课程通过基于Spring MVC和Spring WebFlux的服务实例对比学习，带大家了解Spring 5的新特性和类库升级时应注意的事项，掌握响应式编程范式并能够根据业务需求进行选型和开发。",
        totalhour: 14,
        learnpeple: 100,
        score: 98.0,
        originalprice: 500.0,
        chaptercount: 12,
        lessoncount: 33,
        addbycd: "admin",
        addtime: "2022-03-06 12:31:20",
        editbycd: "admin",
        edittime: "2022-03-13 03:13:43",
        active: 1,
        chapterlist: [
          {
            id: 1,
            name: "第一章",
            coursevideos: [
              {
                id: 2,
                courseid: 1,
                number: 1,
                name: "第1节",
                parentid: 1,
                introd: "33",
                addbycd: "admin",
                addtime: "2022-03-10 06:20:11",
                editbycd: "admin",
                edittime: "2022-03-10 07:26:20",
              },
            ],
          },
          {
            id: 3,
            name: "第二章",
            coursevideos: [
              {
                id: 4,
                courseid: 1,
                number: 1,
                name: "每1节",
                parentid: 3,
                freeis: 1,
                addbycd: "admin",
                addtime: "2022-03-10 07:24:47",
                editbycd: "admin",
                edittime: "2022-03-10 07:24:47",
                active: 1,
              },
              {
                id: 5,
                courseid: 1,
                number: 2,
                name: "第2节",
                parentid: 3,
                freeis: 1,
                addbycd: "admin",
                addtime: "2022-03-10 07:24:59",
                editbycd: "admin",
                edittime: "2022-03-10 07:24:59",
                active: 1,
              },
              {
                id: 6,
                courseid: 1,
                number: 3,
                name: "第3课",
                parentid: 3,
                freeis: 1,
                addbycd: "admin",
                addtime: "2022-03-14 03:26:22",
                editbycd: "admin",
                edittime: "2022-03-14 03:26:22",
                active: 1,
              },
            ],
          },
        ],
      },
    };
  },
};
</script>
<style scoped>
@import url("../../assets/css/learnlist.css");
</style>
